@import 'mixins';
@import 'variables';

.modal {
  width: $modal-width;
  outline: 0;
  max-height: 100vh;
  overflow-y: auto;
  border-radius: 36px;
  background-color: #181d29;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
  padding: 16px;
}

.overlay {
  @include flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(black, 0.75);
  z-index: $zindex-modal;
}
